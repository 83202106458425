import React, { FC, useEffect } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { motion, useAnimation } from 'framer-motion'

import Button from 'components/Button'

import { IServiceDisplay } from './types'
import LoadingState from './LoadingState'
import {
  ServicesWrapper,
  OurServices,
  ServiceTitle,
  ServiceDescription,
  ButtonsWrapper,
  LoadingWrapper,
  ServicesButtonsWrapper,
  BackgroundTransition,
  BackgroundGradient,
} from './styled'

import { Theme, useMediaQuery } from '@mui/material'
import Controls from 'components/TimedCarousel/Controls'

const ServiceDisplay: FC<IServiceDisplay> = ({
  activeService,
  isLoading,
  nextSubService,
  prevSubService,
  backgroundImage,
  hasVideo,
  serviceTitle,
}) => {
  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'))

  const controls = useAnimation()
  const { pathname } = useRouter()

  useEffect(() => {
    if (activeService?.id) {
      const animateIn = async () => {
        await controls.set({ translateY: '-20vh', opacity: 0 })
        await controls.start({ translateY: 0, opacity: 1 })
      }

      animateIn()
    }
  }, [activeService, controls])

  if (isLoading || !activeService) {
    return (
      <LoadingWrapper>
        <h1 style={{ color: 'white' }}>
          {activeService} and {isLoading}
        </h1>
        <LoadingState />
      </LoadingWrapper>
    )
  }

  return (
    <>
      <motion.div animate={controls} transition={{ duration: 0.5 }} style={{ zIndex: 1 }}>
        <ServicesWrapper>
          <OurServices component={pathname === '/' ? 'h2' : 'h1'}>{serviceTitle}</OurServices>
          <ServiceTitle>{activeService?.name}</ServiceTitle>
          <ServiceDescription>{activeService?.description}</ServiceDescription>
          <ButtonsWrapper>
            {activeService?.slug && (
              <Button
                buttonvariant="dark"
                href={`services/${activeService?.slug}?selectedSubServiceId=${activeService?.id}`}
              >
                Explore Services
              </Button>
            )}
          </ButtonsWrapper>
          {!upSm && (
            <ServicesButtonsWrapper>
              <Controls {...{ prevSlide: prevSubService, nextSlide: nextSubService }} />
            </ServicesButtonsWrapper>
          )}
        </ServicesWrapper>
      </motion.div>

      {!hasVideo && (
        <BackgroundTransition
          key={activeService.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <Image
            priority
            layout="fill"
            objectFit="cover"
            placeholder="blur"
            blurDataURL={backgroundImage}
            src={backgroundImage || ''}
            alt={activeService?.name}
          />
          <BackgroundGradient />
        </BackgroundTransition>
      )}
    </>
  )
}
export default ServiceDisplay

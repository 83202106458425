import { useEffect, useState } from 'react'
import { AppBar, Box, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { useQuery } from 'react-query'
import Link from 'next/link'

import Button from 'components/Button'
import { useRouter } from 'next/router'
import SocialMedia from './SocialMedia'
import MobileMenu from './MobileMenu'
import { HamburgerBar, HamburgerIconContainer, NavLink, NavContainer } from './styled'

import StarsIcon from './StarsIcon'

import { HamburgerBarsPosition, links } from './constants'
import ConstanceApi from 'api/ConstanceApi'
import { scrollToElement } from 'utils/pageUtils'
import { jobOpeningsId } from 'shared/constants'
import Image from 'next/image'
import useBookACallUrl from 'hooks/useBookACallUrl'
import { useSiteSettings } from 'hooks/useSiteSettings'

const Header = () => {
  const { bookACallUrl } = useBookACallUrl()

  useSiteSettings()

  const { data } = useQuery({
    queryKey: ['constance'],
    queryFn: () => ConstanceApi.getConstance('SHOW_WE_ARE_HIRING_BUTTON'),
  })

  const router = useRouter()
  const handleWeAreHiringClick = () => {
    if (router.pathname === '/careers') {
      scrollToElement(`#${jobOpeningsId}`, { block: 'start' })
    } else {
      router.push('/careers?register=true')
    }
  }

  const up1000 = useMediaQuery('(min-width:1000px)')

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [leftPosition, setLeftPosition] = useState('-100%')

  useEffect(() => {
    if (isMenuOpen) {
      setLeftPosition('0')
    } else {
      setLeftPosition('-100%')
    }
  }, [isMenuOpen])

  return (
    <Box component="div">
      <AppBar
        position="fixed"
        component="header"
        sx={{
          height: '5rem',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          boxShadow: 'none',
          backdropFilter: 'blur(7.5px)',
          padding: [0, 0, '0 24px'],
        }}
      >
        <Toolbar sx={{ height: '100%' }}>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box component="div" sx={{ cursor: 'pointer' }}>
              <Link passHref href="/">
                <Box component="div">
                  <Image src={'/assets/logo.webp'} width={182} height={36} alt="logo" />
                </Box>
              </Link>
            </Box>
            {up1000 && (
              <NavContainer>
                {links.map((link, index) => (
                  <Link passHref href={link.link} key={index}>
                    <NavLink
                      target={link.target ?? undefined}
                      title={link.title}
                      style={{ textDecoration: 'none' }}
                      isActive={router.pathname === link.link}
                    >
                      {link.title}
                    </NavLink>
                  </Link>
                ))}
                {data && data?.length > 0 && data[0].value && (
                  <Button
                    onClick={handleWeAreHiringClick}
                    sx={{ background: 'transparent' }}
                    buttonvariant="cta2"
                  >
                    <StarsIcon
                      fontSize="small"
                      sx={{ height: '11px', width: '10.5px', marginRight: '0.5rem' }}
                    />
                    <Typography variant="button" fontWeight={600} sx={{ textTransform: 'none' }}>
                      We are hiring!
                    </Typography>
                  </Button>
                )}
                <Button
                  buttonvariant="cta2"
                  href={bookACallUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Book a call
                </Button>
              </NavContainer>
            )}
          </Box>

          {!up1000 && (
            <HamburgerIconContainer onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <Box component="div" sx={{ width: '18px', height: '12px', cursor: 'pointer' }}>
                {HamburgerBarsPosition.map((position, index) => (
                  <HamburgerBar key={index} order={position} open={isMenuOpen} />
                ))}
              </Box>
            </HamburgerIconContainer>
          )}
        </Toolbar>
      </AppBar>

      {up1000 ? (
        <Box
          component="div"
          sx={{ position: 'fixed', top: 'calc(50% - 96px)', right: '4.25rem', zIndex: 3 }}
        >
          <SocialMedia
            sx={{
              flexDirection: 'column',
              marginLeft: 0,
              marginTop: 0,
              marginBottom: 0,
              gap: '2rem',
            }}
          />
        </Box>
      ) : (
        <MobileMenu leftposition={leftPosition} setIsMenuOpen={setIsMenuOpen} />
      )}
    </Box>
  )
}

export default Header

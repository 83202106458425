import { useQuery } from 'react-query'
import useBookACallUrl from './useBookACallUrl'
import ConstanceApi from 'api/ConstanceApi'

export function useSiteSettings() {
  const { setBookACallUrl } = useBookACallUrl()

  useQuery({
    queryKey: ['bookACallUrl'],
    queryFn: () => ConstanceApi.getConstance('BOOK_A_CALL_BUTTON_URL'),
    onSuccess: (data) => {
      if (data?.length > 0 && data[0].value) {
        setBookACallUrl(data[0].value as string)
      }
    },
    onError: () => {
      setBookACallUrl('')
    },
  })
}

import Link from 'next/link'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { Box, Typography } from '@mui/material'

import { scrollToElement } from 'utils/pageUtils'
import { jobOpeningsId } from 'shared/constants'
import Button from 'components/Button'
import BarsIcon from '../BarsIcon'
import { links } from '../constants'
import SocialMedia from '../SocialMedia'
import { MobileNav } from './styled'

import ConstanceApi from 'api/ConstanceApi'
import useBookACallUrl from 'hooks/useBookACallUrl'

const MobileMenu = ({
  leftposition,
  setIsMenuOpen,
}: {
  leftposition: string
  setIsMenuOpen: (arg0: boolean) => void
}) => {
  const { data } = useQuery({
    queryKey: ['constance'],
    queryFn: () => ConstanceApi.getConstance('SHOW_WE_ARE_HIRING_BUTTON'),
  })

  const { bookACallUrl } = useBookACallUrl()

  const router = useRouter()

  const handleWeAreHiringClick = () => {
    setIsMenuOpen(false)
    if (router.pathname === '/careers') {
      scrollToElement(`#${jobOpeningsId}`, { block: 'start' })
    } else {
      router.push('/careers?register=true')
    }
  }

  return (
    <MobileNav leftposition={leftposition}>
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
        {links.map((link, index) => (
          <Box component="div" key={index} onClick={() => setIsMenuOpen(false)}>
            <Link passHref href={link.link}>
              <a
                target={link.target ?? undefined}
                title={link.title}
                style={{ textDecoration: 'none' }}
              >
                <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                  <BarsIcon
                    sx={{
                      height: '25px',
                      width: '25px',
                      color: router.asPath === link.link ? 'surface.50' : 'transparent',
                    }}
                  />
                  <Typography variant="h4" color="surface.50">
                    {link.title}
                  </Typography>
                </Box>
              </a>
            </Link>
          </Box>
        ))}
        {data && data?.length > 0 && data[0].value && (
          <Box
            component="div"
            ml="1.5rem"
            onClick={handleWeAreHiringClick}
            sx={{ cursor: 'pointer' }}
          >
            <Typography variant="h4" color="surface.50">
              We are hiring!
            </Typography>
          </Box>
        )}
      </Box>

      <Box component="div" ml="1.5rem" mt={6}>
        <Button buttonvariant="cta2" href={bookACallUrl} target="_blank" rel="noopener noreferrer">
          Book a call
        </Button>
      </Box>

      <SocialMedia />
    </MobileNav>
  )
}

export default MobileMenu

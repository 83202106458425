import { create } from 'zustand'

interface UseBookACallUrlState {
  bookACallUrl: string
  setBookACallUrl: (newData: string) => void
}

const useBookACallUrl = create<UseBookACallUrlState>((set) => ({
  bookACallUrl: '',
  setBookACallUrl: (newData) => set({ bookACallUrl: newData }),
}))

export default useBookACallUrl

import { useCallback } from 'react'
import { useTheme } from '@mui/material'
import SouthIcon from '@mui/icons-material/South'

import Button from 'components/Button'
import HeroCanvas from './HeroCanvas'

import {
  Subtitle,
  Title,
  HeroContainer,
  ArrowContainer,
  MainContentContainer,
  TextContainer,
  StyledContainer,
  ButtonContainer,
  BookACallButton,
  ArrowBox,
  WrapperContainer,
} from './styled'

import { scrollToElement } from 'utils/pageUtils'
import { trustedBySectionId } from 'shared/constants'
import { use100vh } from 'react-div-100vh'
import { HeroTitleAnimation } from './HeroTitleAnimation'
import useBookACallUrl from 'hooks/useBookACallUrl'

const Hero = () => {
  const { bookACallUrl } = useBookACallUrl()

  const theme = useTheme()
  const height100vh = use100vh()

  const handleNextSectionClick = useCallback(() => {
    scrollToElement(`#${trustedBySectionId}`, { block: 'center' }, false)
  }, [])

  return (
    <WrapperContainer>
      <HeroContainer height100vh={height100vh}>
        <MainContentContainer>
          <StyledContainer>
            <TextContainer>
              <Title aria-label="we make ideas happen">
                <span aria-hidden="true">
                  We make <br />
                  <HeroTitleAnimation />
                  <br />
                  happen
                </span>
                <Subtitle component="span">Let&apos;s build it together!</Subtitle>
              </Title>
            </TextContainer>

            <ButtonContainer sx={{ display: { xs: 'none', md: 'block' } }}>
              <Button
                buttonvariant="cta2"
                href={bookACallUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Book a call
              </Button>
            </ButtonContainer>
          </StyledContainer>
        </MainContentContainer>

        <StyledContainer>
          <HeroCanvas />
        </StyledContainer>

        <ArrowContainer height100vh={height100vh}>
          <BookACallButton
            buttonvariant="cta2"
            href={bookACallUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Book a call
          </BookACallButton>
          <ArrowBox onClick={handleNextSectionClick}>
            <SouthIcon sx={{ color: theme.palette.surface.light }} />
          </ArrowBox>
        </ArrowContainer>
      </HeroContainer>
    </WrapperContainer>
  )
}

export default Hero
